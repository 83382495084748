/* for hiding process is not defined bug from console */
body
  > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}
.chart {
  height: 320px !important;
  width: 320px !important;
  justify-items: center;
  margin: auto;
}

.drawer {
  position: fixed;
  z-index: 9999;
  transition: width 0s ease 0.3s, height 0s ease 0.3s,
    transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer > * {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer .drawer-mask {
  background: #000;
  opacity: 0;
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    height 0s ease 0.3s;
}
.drawer-content-wrapper {
  position: absolute;
  background: #fff;
}
.drawer-content {
  overflow: auto;
  z-index: 1;
  position: relative;
}
.drawer-handle {
  position: absolute;
  top: 72px;
  width: 41px;
  height: 40px;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: none;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.drawer-handle-icon {
  width: 14px;
  height: 2px;
  background: #333;
  position: relative;
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before,
.drawer-handle-icon:after {
  content: '';
  display: block;
  position: absolute;
  background: #333;
  width: 100%;
  height: 2px;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before {
  top: -5px;
}
.drawer-handle-icon:after {
  top: 5px;
}
.drawer-left,
.drawer-right {
  width: 0%;
  height: 100%;
}
.drawer-left .drawer-content-wrapper,
.drawer-right .drawer-content-wrapper,
.drawer-left .drawer-content,
.drawer-right .drawer-content {
  height: 100%;
}
.drawer-left.drawer-open,
.drawer-right.drawer-open {
  width: 100%;
}
.drawer-left.drawer-open.no-mask,
.drawer-right.drawer-open.no-mask {
  width: 0%;
}
.drawer-left {
  top: 0;
  left: 0;
}
.drawer-left .drawer-handle {
  right: -40px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 4px 4px 0;
}
.drawer-left.drawer-open .drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right {
  top: 0;
  right: 0;
}
.drawer-right .drawer-content-wrapper {
  right: 0;
}
.drawer-right .drawer-handle {
  left: -40px;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0 0 4px;
}
.drawer-right.drawer-open .drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right.drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.drawer-top,
.drawer-bottom {
  width: 100%;
  height: 0%;
}
.drawer-top .drawer-content-wrapper,
.drawer-bottom .drawer-content-wrapper,
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  width: 100%;
}
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  height: 100%;
}
.drawer-top.drawer-open,
.drawer-bottom.drawer-open {
  height: 100%;
}
.drawer-top.drawer-open.no-mask,
.drawer-bottom.drawer-open.no-mask {
  height: 0%;
}
.drawer-top .drawer-handle,
.drawer-bottom .drawer-handle {
  left: 50%;
  margin-left: -20px;
}
.drawer-top {
  top: 0;
  left: 0;
}
.drawer-top .drawer-handle {
  top: auto;
  bottom: -40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 4px 4px;
}
.drawer-top.drawer-open .drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom {
  bottom: 0;
  left: 0;
}
.drawer-bottom .drawer-content-wrapper {
  bottom: 0;
}
.drawer-bottom .drawer-handle {
  top: -40px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
}
.drawer-bottom.drawer-open .drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom.drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.drawer.drawer-open .drawer-mask {
  opacity: 0.3;
  height: 100%;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open .drawer-handle-icon {
  background: transparent;
}
.drawer.drawer-open .drawer-handle-icon:before {
  transform: translateY(5px) rotate(45deg);
}
.drawer.drawer-open .drawer-handle-icon:after {
  transform: translateY(-5px) rotate(-45deg);
}

/* drawer */
.drawer {
  overflow: visible;
  z-index: 9989 !important;
}
.drawer .drawer-content-wrapper {
  width: 50vw;
  transform: translateX(0px);
}
.drawer .drawer-content-wrapper .drawer-handle {
  display: none !important;
}
@media (min-width: 320px) and (max-width: 767px) {
  .drawer .drawer-content-wrapper {
    width: 100vw;
  }
}

/* modal */
.custom-modal header button {
  display: none;
}

/*input tag css */
.react-tag-input {
  width: 100%;
  background: #f4f5f7;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  padding: 0;
}
.react-tag-input__input {
  font-size: 0.875rem;
  margin: 0;
  height: 3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background: transparent;
  color: #4c4f52;
}
.react-tag-input__input:focus {
  background: #ffffff;
}
.react-tag-input__input::placeholder,
.react-tag-input__input:-moz-placeholder,
.react-tag-input__input:-ms-input-placeholder,
.react-tag-input__input::-moz-placeholder,
.react-tag-input__input::-webkit-input-placeholder {
  font-size: 10px;
  font-weight: 400;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.react-tag-input__tag {
  margin: 0.175rem 0.375rem;
  color: #24262d;
}
.theme-dark .react-tag-input {
  color: #ebebeb;
  border-color: #4c4f52;
  background-color: #24262d;
}
.theme-dark .react-tag-input__input {
  color: #ebebeb;
}
.theme-dark .react-tag-input__input:focus {
  background-color: #24262d;
  border-color: #707275;
}

/* dropdown style */
.dropdown {
  display: none !important;
}

/* notification box css */

.notification-box {
  width: 20rem;
  height: 22rem;
}
.notification-content h6 {
  font-size: 13px;
  margin-bottom: 5px;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.notification-content p span {
  font-size: 11px;
}
.additional-notes {
  border-left: 1px solid #e2e8f0;
  padding-left: 1rem;
}
.image-container {
  display: flex;
  flex-wrap: wrap;
}

.damaged-photo {
  width: 25%;
  height: 34px;
  cursor: pointer;
}

.receipt-photo {
  width: 50%;
  height: 120px;
  margin-right: 10px;
  cursor: pointer;
}
/* custom toggle */





/* .toggle {
  width: 60px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.toggle-button {
  width: 30px;
  height: 30px;
  background-color: rgb(120, 118, 118);
  border-radius: 50%;
  transform: translateX(0);
  transition: transform 0.3s ease;
}

.toggle-button.translate-x-full {
  transform: translateX(30px);
  background-color: rgb(40, 37, 37);
} */




.reports-table>tbody>tr:nth-child(even) {
  background-color:#f4f5f7;
}



.check-box-filter-text {
  font-size: 14px;
}

@media screen and (max-width: 1340px) {
  .check-box-filter-text {
    font-size: 12px;
  }
}

.inventroy-link-class {
  width: auto !important;
  padding: 0 8px !important;
}






.switch {
  display: block;
  margin: 22px auto;
}

.switch {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 96px;
  height: 33px;
  padding: 1px;
  border-radius: 3px;
  cursor: pointer;
}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.switch-label {
  position: relative;
  display: block;
  height: 32px;
  width: 80px;
  font-size: 10px;
  text-transform: uppercase;
  background: #fff;
  border-radius: 50px;
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  -o-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  border: 1px solid #cdcdcd;

}
.switch-label:before, .switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}
.switch-label:before {
  content: attr(data-off);
  right: 9px;
  color: #aaa;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}
.switch-input:checked ~ .switch-label {
  background: #da2b39;
}
.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}

.switch-handle {
  position: absolute;
  top: 3px;
  width: 28px;
  height: 28px;
  left: 3px;
  background: white;
  border-radius: 50px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
  background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}

.switch-input:checked ~ .switch-handle {
  left: 52px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-green > .switch-input:checked ~ .switch-label {
  background: #4fb845;
}







.MuiFormControlLabel-root {
  font-size: 0.8rem;
}

.file-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}
.file-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #f2f2f2;
  border-radius: 8px;
  text-align: center;
}
.file-logo {
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
}

.photo-zoom {
  cursor: pointer;
  width: 100%;
  height: auto;
  object-fit: contain;
  overflow-y: auto;
}
.picture-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow-y: auto;
}

/* Labels Page Css */
.qr-business-card {
  height: 100px;
  width: 174px;
  margin: 0 auto;
  background: #FFFFFF;
  border: 1px solid #000000;
}

.qr-business-card .sub-heading {
  width: 90px;
  font-weight: 900;
}

.qr-business-card .qr-Svg {
  /* margin-left: 75%; */
  max-height: 20%;
  margin-left: 60%;
  margin-top: 20%;
}

.qr-business-card .qr-Svg img {
  width: 37px;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  padding: 8px 0;
}

.custom-checkbox input:checked {
  border-color: black; /* Change the border color when checked */
}


.qr-printer-friendly {
  height: 231px;
  width: 179px;
  margin: 0 auto;
  background: #FFFFFF;
  border: 1px solid #000000;
}

.qr-printer-friendly-2 {
  height: 179px;
  /* margin: 0 auto; */
  background: #FFFFFF;
  border: 1px solid #000000;
}

.qr-Svg-8 img {
  width: 40px;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  /* padding: 10px 0; */
  padding-top: 10px;
}

.friendly-scan {
  text-align: center;
  font-size: 5px;
  margin-left: 85px;
  font-weight: 900;
}

.friendly-scan-2 {
  text-align: center;
  font-size: 5px;
  /* margin-left: 142px; */
  font-weight: 900;
}

.friendly-detail {
  /* text-align: center;
  font-size: 5px;
  margin-left: 85px;
  font-weight: 900; */
  font-size: 6px;
  margin: 30 auto;
}

.f-sub-rows {
  font-size: 7px;
}

.qr-business-text {
  font-size: 9px;
}

.qr-business-f-sub-heading {
  font-size: 5px;
}

/* .qr-business-card {

} */

.qr-card {
  height:174px;
  width: 174px;
  margin: 0 auto;
  background: #FFFFFF;
  border: 1px solid #000000;
}

.heading {
  text-align: center;
  color: #DB2B39;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 700;
  font-family: 'Futura BdCn BT', sans-serif;
}

.sub-heading {
  font-family: 'Futura Md BT', sans-serif;
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #000000;
  font-size: 10px;
}

.qr-Svg {
  text-align: center;
}

.qr-Svg img {
  width: 77px;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  padding: 8px 0;
}

.footer-heading {
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 700;
  font-family: 'Futura Md BT', sans-serif;

}

.f-heading {
  color: #DB2B39;
}

.f-sub-heading {
  text-align: center;
  font-size: 5px;
}

.f-logo img{
 margin: 0 auto;
/*width: 50px;*/
height: 10px;

}

/*============Qr-2================*/

.qr-card-2 {
  height: 171px;
  width: 171px;
  margin: 0 auto;
  background: #FFFFFF;
  border: 1px solid #000000;
}

.heading-2 {
  text-align: center;
  color: #DB2B39;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 700;
  font-family: 'Futura BdCn BT', sans-serif;
  padding: 30px 0;
}

.sub-heading-2 {
  font-family: 'Futura Md BT', sans-serif;
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #000000;
  font-size: 10px;
}

.qr-Svg-2 {
  text-align: center;
}

.qr-Svg-2 img {
  width: 105px;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  padding: 12px 0;
}

.footer-heading-2 {
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 700;
  font-family: 'Futura Md BT', sans-serif;

}

.f-heading-2 {
  color: #DB2B39;
}

.f-logo-2 img{
  margin: 0 auto;
  height: 10px;
  /*width: 50px;*/
}


/*=============Qr-3===============*/
.qr-card-3 {
  height: 231px;
  width: 179px;
  margin: 0 auto;
  background: #FFFFFF;
  border: 1px solid #000000;
}

.heading-3 {
  text-align: center;
  color: #000000;
  font-size: 34px;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 700;
  font-family: 'Futura BdCn BT', sans-serif;
  padding-top: 10px;
}

.sub-heading-3 {
  font-family: 'Futura Md BT', sans-serif;
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #000000;
  font-size: 24px;
}

.qr-Svg-3 {
  text-align: center;
}

.qr-Svg-3 img {
  width: 87px;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  padding: 8px 0;
}

.footer-heading-3 {
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 700;
  font-family: 'Futura Md BT', sans-serif;

}

.f-sub-heading-3 {
  text-align: center;
  font-size: 5px;
  padding-bottom: 10px;
}

.f-logo-3 img{

}

/*=================Qr-4====================*/
.qr-card-4 {

  background: #FFFFFF;
  border: 1px solid #000000;
}

.heading-4 {
  text-align: center;
  color: #000000;
  font-size: 29px;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 700;
  font-family: 'Futura BdCn BT', sans-serif;
  padding-top: 12px;
}

.sub-heading-4 {
  font-family: 'Futura Md BT', sans-serif;
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #000000;
  font-size: 11px;
}

.qr-Svg-4 img {
  width: 59px;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px 0;
}

.footer-heading-4 {
  text-align: center;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 700;
  font-family: 'Futura Md BT', sans-serif;

}

.f-sub-heading-4 {
  text-align: center;
  font-size: 6px;
  padding-bottom: 12px;
}



/*==============Qr-5=============*/
.qr-card-5 {
  height:230px ;
  width: 178px;
  margin: 0 auto;
  background: #FFFFFF;
  border: 1px solid #000000;
}

.heading-5 {
  text-align: center;
  color: #000000;
  font-size: 60px;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 700;
  font-family: 'Futura BdCn BT', sans-serif;
  padding: 30px 0;
}

.sub-heading-5 {
  font-family: 'Futura Md BT', sans-serif;
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #000000;
  font-size: 28px;
}

.qr-Svg-5 {
  text-align: center;
}

.qr-Svg-5 img {
  width: 108px;
  height: auto;
  padding-top: 40px;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 30px;
}

.footer-heading-5 {
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 700;
  font-family: 'Futura Md BT', sans-serif;

}

.f-sub-heading-5 {
  text-align: center;
  font-size: 5px;
}

.f-logo-5 {
  text-align: center;
  padding: 20px 0;
}

/*==============Qr-6=================*/
.qr-card-6 {
  background: #FFFFFF;
  border: 1px solid #000000;
}

.heading-6 {
  text-align: center;
  color: #000000;
  font-size: 60px;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 700;
  font-family: 'Futura BdCn BT', sans-serif;
  padding: 30px 0;
}

.sub-heading-6 {
  font-family: 'Futura Md BT', sans-serif;
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #000000;
  font-size: 28px;
}

.qr-Svg-6 {
  text-align: center;
}

.qr-Svg-6 img {
  width: 106px;
  height: auto;
  padding-top: 20px;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 10px;
}

.footer-heading-6 {
  text-align: center;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 700;
  font-family: 'Futura Md BT', sans-serif;

}

.f-sub-heading-6 {
  text-align: center;
  font-size: 6px;
  padding-bottom: 15px;
}

.f-logo-6 {
  text-align: center;
  padding: 20px 0;
}
.f-notes{
  text-align: center;
}
.f-notes .download-link{
  color: #DB2B39;
  cursor: pointer;
}